exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-aanpak-tsx": () => import("./../../../src/pages/aanpak.tsx" /* webpackChunkName: "component---src-pages-aanpak-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-projecten-tsx": () => import("./../../../src/pages/projecten.tsx" /* webpackChunkName: "component---src-pages-projecten-tsx" */),
  "component---src-pages-sproeisysteem-tsx": () => import("./../../../src/pages/sproeisysteem.tsx" /* webpackChunkName: "component---src-pages-sproeisysteem-tsx" */),
  "component---src-pages-tuinaanleg-tsx": () => import("./../../../src/pages/tuinaanleg.tsx" /* webpackChunkName: "component---src-pages-tuinaanleg-tsx" */),
  "component---src-pages-tuinonderhoud-tsx": () => import("./../../../src/pages/tuinonderhoud.tsx" /* webpackChunkName: "component---src-pages-tuinonderhoud-tsx" */),
  "component---src-pages-vijvers-en-koi-tsx": () => import("./../../../src/pages/vijvers-en-koi.tsx" /* webpackChunkName: "component---src-pages-vijvers-en-koi-tsx" */)
}

